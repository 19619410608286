import React, { Component } from 'react';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import constClass from '../../Constants/Constants';
import Loading from '../Loading/Loading';
import Modal from 'react-modal';
import "react-accessible-accordion/dist/fancy-example.css";
import ScrollToTop from '../Scroll/ScrollToTop';
import DocumentMeta from 'react-document-meta';
import Payment from '../Payment/Payment';
// import Pointcard from './Pointcard'
// import Liffconfirm from './Liffconfirm'
import Auth from './Auth';
import AuthVerify from './AuthVerify';
import Menu from './Menu';
import User from './User';
import UserChange from './UserChange';
import Renewal from './Renewal';
import Order from './Order';
import Common from '../Common/common';
import SpecifiedCommercial from './SpecifiedCommercial';
import Guide from './Guide';

Modal.setAppElement("#root");

class Liff extends Component {
  constructor(props) {
    super(props);
    this.state = {
      disabled: false,
      my_order: null,
      ready: false,
      my_customer: {
        mail_address: '',
        gender: '',
        birth: '',
        postal_code: '',
        card_id: '',
        privacy_policy: constClass.FLAG.OFF,
        cancel_cause: '',
        m_coupon_code: []
      },
      original_customer: {},
      campaignlist: null,
      campaign: { campaign_id: '', m_site_card: [] },
      coupon_list: null,
      siteId: isFinite(props.match.params.siteId) ? props.match.params.siteId : 0,
      coupon_code: null,
      popover_message: {},
      message: {
        mail_address: '',
        gender: '',
        birth: '',
        postal_code: '',
        card_id: '',
        phone_number: '',
      },
      copy_message: null,
      user_change: false,
      modal_flag: false,
      pre_modal_flag: false,
      ticket_list: null,
      ng_coupon_list: [],
      my_entry: {
        campaign_id: '',
        status: constClass.STATUS.UREG,
        privacy_policy: constClass.FLAG.OFF
      },
      headerHeight: 0,
      footerHeight: 0,
      setting: null,
      initialQs: null,
      membership: [],
      membership_history: [],
      customer_detail_type: [],
      back: {
        title: '戻る',
        render: true,
        disabled: false,
        onClick: () => {
          if (this.props.history.length <= 1) {
            this.props.history.push(`/${this.state.siteId}/`);
          } else {
            this.props.history.goBack();
          }
          this.scrollPageTop();
        }
      },
      next: {
        title: '次へ',
        render: true,
        disabled: false,
        onClick: () => { }
      },
      not_liff_flag: false,
    };

    this.modalStyle = {
      overlay: {
        position: "fixed",
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: "rgba(0,0,0,0.5)"
      },
      content: {
        position: "absolute",
        left: '0.5rem',
        right: '0.5rem',
        top: '10vw',
        bottom: 'auto',
        marginTop: '0.5rem',
        borderRadius: "0rem",
        padding: "0px",
        height: "auto"
      }
    };

    this.refreshData = this.refreshData.bind(this);
    this.setReady = this.setReady.bind(this);
    this.returnPayment = this.returnPayment.bind(this);
    this.backPayment = this.backPayment.bind(this);
    this.setBack = this.setBack.bind(this);
    this.setNext = this.setNext.bind(this);
    this.setMyCustomer = this.setMyCustomer.bind(this);
    this.setPaymentData = this.setPaymentData.bind(this);
    this.pageRef = React.createRef();
    this.intervalPop = [];
    this.headerRef = React.createRef();
    this.footerRef = React.createRef();
    this.paymentRef = React.createRef();
  }

  async componentDidMount() {
    // サイト情報取得
    var site = (await axios.post(`${process.env.REACT_APP_BACKEND_URL}/site/search/`, { site_id: this.state.siteId })).data;
    this.site_name = site.site_name;
    this.meta = {
      link: {
        rel: {
          icon: `/${this.state.siteId}/favicon.ico`,
          "apple-touch-icon": `/${this.state.siteId}/logo192.png`,
          manifest: `/${this.state.siteId}/manifest.json`,
          stylesheet: `/${this.state.siteId}/style.css`,
        },
      },
      title: site ? site.site_name : '',
      description: site ? site.site_name : '',
    }

    this.refreshData();
    this.disableBounceScroll();
    // window.addEventListener('popstate', this.resetCustomer, false);
  }

  componentDidUpdate(prevProps, prevState) {
    const height = { header: prevState.headerHeight, footer: prevState.footerHeight };
    if (this.headerRef.current) {
      const rect = this.headerRef.current.getBoundingClientRect();
      if (height.header !== rect.height) {
        this.setState({ headerHeight: rect.height });
      }
    }
    if (this.footerRef.current) {
      const rect = this.footerRef.current.getBoundingClientRect();
      if (height.footer !== rect.height) {
        this.setState({ footerHeight: rect.height });
      }
    }
  }

  async componentWillUnmount() {
    // clearInterval(this.intervalCount);
    // window.removeEventListener('popstate', this.resetCustomer, false);
  }

  disableBounceScroll() {
    let touchY = 0;

    document.body.addEventListener('touchstart', (e) => {
      touchY = e.touches[0].screenY;
    });

    document.body.addEventListener('touchmove', (e) => {
      let el = e.target;
      // let el = this.pageRef.current;// e.targetが機種によって変動するため、pageに固定
      const moveY = e.touches[0].screenY;
      let noScroll = true;

      while (el !== null) {
        if (el.offsetHeight < el.scrollHeight) {
          if (touchY < moveY && el.scrollTop === 0) {
            break;
          }

          if (touchY > moveY && el.scrollTop === el.scrollHeight - el.offsetHeight) {
            break;
          }

          noScroll = false;
          break;
        }
        // console.log(`moveY=${moveY}, touchY=${touchY}, offsetHeight=${el.offsetHeight}, scrollHeight=${el.scrollHeight}, scrollTop=${el.scrollTop}, className=${el.className}, id=${el.id}, ${el.localName}`);
        el = el.parentElement;
      }
      // console.log(`moveY=${moveY}, touchY=${touchY}, offsetHeight=${el.offsetHeight}, scrollHeight=${el.scrollHeight}, scrollTop=${el.scrollTop}, className=${el.className}, id=${el.id}, ${el.localName}`);
      if (noScroll) {
        if (e.cancelable) {
          e.preventDefault();
        }
      }

      touchY = moveY;
    }, { passive: false });
  }

  setBack(back) {
    this.setState({
      back
    });
  }

  setNext(next) {
    this.setState({
      next
    });
  }

  setMyCustomer(my_customer) {
    // my_customer.customer_name = my_customer.customer_name_sei + ' ' + my_customer.customer_name_mei;
    // my_customer.customer_kana = my_customer.customer_kana_sei + ' ' + my_customer.customer_kana_mei;
    this.setState({
      my_customer: this.customerToState(my_customer)
    });
  }

  setPaymentData(payment_data, returnFunc) {
    this.paymentRef.current = returnFunc;
    this.setState({ payment_data });
  }

  scrollPageTop() {
    setTimeout(() => {
      this.pageRef.current.scrollTo(0, 0);
      // window.scrollTo(0, 0);
    }, 1);
  }

  scrollWindowTop() {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 1);
  }

  async refreshData(reload = false) {
    if (process.env.REACT_APP_ENV !== 'dev') {
      await window.liff.ready;
    }
    if (!this.props.liff_access_token) {
      if (!this.intervalMaster) {
        this.intervalMaster = setInterval(() => {
          this.refreshData();
        }, 500);
      }
    } else {
      clearInterval(this.intervalMaster);
      if (!this.state.siteId) { // site未設定
        this.props.history.replace(`/1/?page=`); // site一覧からの設定処理？→暫定でリダイレクト
        return;
      }
      //設定取得
      const settingPromise = axios.get(`${process.env.REACT_APP_BACKEND_URL}/setting/${this.state.siteId}`);
      const setting = (await settingPromise).data;
      if (process.env.REACT_APP_ENV !== 'dev' && this.props.liff_access_token === 'token_unusable') { // トークン取得不可→非LINEブラウザ
        this.setState({ setting, not_liff_flag: true });
        return;
      }
      // 各種APIコール
      const params = { site_id: this.state.siteId, line_id: this.props.liff_access_token, status: [constClass.STATUS.REG, constClass.STATUS.UPD] };
      const customerPromise = axios.post(`${process.env.REACT_APP_BACKEND_URL}/customer/line/search/`, params, { withCredentials: true });
      const membership_params = {
        site_id: this.state.siteId,
        line_id: this.props.liff_access_token,
        operator: "and",
        where: [
          { site_id: this.state.siteId },
          {
            operator: "lte",
            attr: "renewal_start",
            val: new Date()
          },
          {
            operator: "gte",
            attr: "renewal_end",
            val: new Date()
          },
        ]
      }
      const membershipPromise = axios.post(`${process.env.REACT_APP_BACKEND_URL}/membership/line/search/`, membership_params);
      const membershipHistoryPromise = axios.post(`${process.env.REACT_APP_BACKEND_URL}/membership/line/search/history/`, membership_params);
      const customer = (await customerPromise).data;
      var my_customer = customer.find(c => c.line_id === this.props.liff_user_id);
      if (!my_customer) {
        // データが無ければ
      } else {
        // 姓・名を統合した値を作成しておく → backendで処理するように変更
        // my_customer.customer_name = my_customer.customer_name_sei + ' ' + my_customer.customer_name_mei;
        // my_customer.customer_kana = my_customer.customer_kana_sei + ' ' + my_customer.customer_kana_mei;
      }

      const initialQs = this.state.initialQs ? this.state.initialQs : {
        page: this.props.page,
        current_id: this.props.current_id,
      };
      // 未登録のユーザは必ず登録画面に遷移
      if (!my_customer || [constClass.STATUS.CCL].includes(my_customer.status)) {
        // this.setState({ pre_modal_flag: true });
        this.props.history.replace(`/${this.state.siteId}/?page=${constClass.AUTH}`);
      } else {
        // 登録済みでトップページ（登録画面）を開いた場合
        if (my_customer && [constClass.STATUS.REG, constClass.STATUS.UPD].includes(my_customer.status) && (this.props.page === undefined || this.props.page === null || this.props.page === '')) {
          if (initialQs.page === undefined || initialQs.page === null || initialQs.page === '') {
            this.props.history.replace(`/${this.state.siteId}/?page=${constClass.MENU}`);
          } else {
            var url = `/${this.state.siteId}/?page=${initialQs.page}`;
            if (initialQs.current_id) {
              url += `&current_id=${initialQs.current_id}`;
            }
            this.props.history.replace(url);
          }
        }
      }

      const membership = (await membershipPromise).data;
      const membership_history = (await membershipHistoryPromise).data;
      this.setState({
        my_customer: this.customerToState(my_customer),
        original_customer: my_customer,
        setting,
        initialQs,
        membership,
        membership_history: membership_history.status === "success" ? membership_history.payment : [],
      });

      this.setReady();
    }
  }

  async returnPayment() {
    try {
      if (this.paymentRef.current !== null) {
        await this.paymentRef.current();
      } else {
        const params = { site_id: this.state.siteId, line_id: this.props.liff_access_token };
        const customer = (await axios.post(`${process.env.REACT_APP_BACKEND_URL}/customer/line/search/`, params)).data;
        var my_customer = customer.find(c => c.line_id === this.props.liff_user_id);
        // my_customer.customer_name = my_customer.customer_name_sei + ' ' + my_customer.customer_name_mei;
        // my_customer.customer_kana = my_customer.customer_kana_sei + ' ' + my_customer.customer_kana_mei;
        this.setState({ my_customer: this.customerToState(my_customer), original_customer: my_customer });
      }
    } catch (err) {
      console.log(err);
    }
    return true;
  }

  async backPayment() {
    if (this.paymentRef.current !== null) {
      await this.paymentRef.current();
    }
    return true;
  }

  setReady() {
    if (this.state.my_customer !== null && this.state.setting !== null) {
      this.setState({ ready: true });
    } else {
      this.setState({ ready: false });
    }
  }

  customerToState(customer) {
    // 退会済みデータは画面に反映しない
    if (!customer || customer.status === constClass.STATUS.CCL) {
      return {
        ...customer,
        customer_no: '',
        customer_name: '',
        customer_kana: '',
        customer_tel: '',
        customer_mail: '',
        customer_postal_code: '',
        customer_address: '',
        customer_address1: '',
        customer_address2: '',
        customer_address3: '',
        customer_birthday: '',
        customer_job: '',
        method: '',
        bank_name: '',
        branch_name: '',
        account_type: constClass.ACCOUNT_TYPE.SAVING,
        account_number: '',
        account_holder: '',
        refund_flag: constClass.FLAG.OFF,
      }
    } else {
      return {
        ...customer,
        customer_no: customer.customer_no ? customer.customer_no : '',
        customer_name: customer.customer_name ? customer.customer_name : '',
        customer_kana: customer.customer_kana ? customer.customer_kana : '',
        customer_tel: customer.customer_tel ? customer.customer_tel : '',
        customer_mail: customer.customer_mail ? customer.customer_mail : '',
        customer_postal_code: customer.customer_postal_code ? customer.customer_postal_code : '',
        customer_address: customer.customer_address ? customer.customer_address : '',
        customer_address1: customer.customer_address1 ? customer.customer_address1 : '',
        customer_address2: customer.customer_address2 ? customer.customer_address2 : '',
        customer_address3: customer.customer_address3 ? customer.customer_address3 : '',
        customer_birthday: customer.customer_birthday ? customer.customer_birthday : '',
        customer_job: customer.customer_job ? customer.customer_job : '',
        method: customer.method ? customer.method : '',
        bank_name: customer.bank_name ? customer.bank_name : '',
        branch_name: customer.branch_name ? customer.branch_name : '',
        account_type: customer.account_type ? customer.account_type : constClass.ACCOUNT_TYPE.SAVING,
        account_number: customer.account_number ? customer.account_number : '',
        account_holder: customer.account_holder ? customer.account_holder : '',
        refund_flag: customer.refund_flag ? customer.refund_flag : constClass.FLAG.OFF,
      }
    }
  }

  renderHeader() {
    const titlename = () => {
      switch (this.props.page) {
        case constClass.AUTH: return '会員認証';
        case constClass.AUTHVERIFY: return '会員認証';
        case constClass.MENU: return 'メニュー';
        case constClass.USER: return `会員情報`;
        case constClass.USERCHANGE: return `会員情報変更`;
        case constClass.RENEWAL: return `更新申込`;
        case constClass.TICKETUSE: return `利用可能なチケット一覧`;
        case constClass.PAYMENT: return `お支払い情報`;
        case constClass.SPECIFIEDCOMMERCIAL: return `特定商取引法に関する表記`;
        case constClass.GUIDE: return `ご利用案内`;
        default: return `メニュー`;
      }
    }
    return (
      <header className="header">
        <div className="row mx-0" ref={this.headerRef}>
          <div className={`col section text-center ${Common.checkOrderPage(this.props.page) ? 'd-none' : ''}`}>
            {/* <img src="logo.png" alt="logo" /> */}
            <span>{titlename(this)}</span>
          </div>
        </div>
      </header>
    );
  }
  renderPagetitle() {
    return (
      <div>
        {/* <div className="row mx-0">
          <div className="col section py-0 line-height-2-2">
            <span className="d-inline-block align-middle">{titlename(this)}</span>
          </div>
        </div> */}
      </div>
    );
  }

  renderBottom() {

    return (
      <footer className="footer text-center">
        <div className="container m-0 p-0 mw-100" ref={this.footerRef}>
          <div className="row mx-0">
            <div className="col text-center p-0">
              <div className="row mx-0">
                {this.state.back.render &&
                  <div className="col-3 text-center p-0">
                    <button
                      className={`btn-lg btn-submit-enable w-100 py-3 p-env-bottom`}
                      disabled={this.state.back.disabled}
                      onClick={this.state.back.onClick}>
                      {this.state.back.title}
                    </button>
                  </div>
                }
                {this.state.next.render &&
                  <div className="col text-center p-0">
                    <button
                      disabled={this.state.next.disabled}
                      className="btn-lg btn-submit w-100 py-3 p-env-bottom"
                      onClick={this.state.next.onClick}>
                      {this.state.next.title}
                    </button>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }

  render() {
    return (
      this.state.ready ? <div className="liff-top bg-lightwhite" style={{ '--header-height': `${this.state.headerHeight}px`, '--footer-height': `${this.state.footerHeight}px` }}>
        <DocumentMeta {...this.meta} />
        <ScrollToTop />
        {this.renderHeader()}
        <div className={`page-${(this.props.page === constClass.MENU || this.props.page === constClass.PAYMENT || Common.checkOrderPage(this.props.page)) ? 'full' : 'btn'}`} ref={this.pageRef}>
          {/* {this.renderPagetitle()} */}
          {/* {(this.props.page === constClass.CONFIRM || this.props.page === constClass.CONFIRM2) &&
            <div>
              {this.state.campaign &&
                <Liffconfirm
                  {...this.props} {...{ condition: { campaign: this.state.campaign, my_customer: this.state.my_customer, my_entry: this.state.my_entry, setting: this.state.setting } }}
                />
              }
              {!this.state.campaign && <Loading />}
            </div>
          }
          {(this.props.page === constClass.POINTLIST || this.props.page === constClass.ENTRY) && this.state.my_entry.status === constClass.STATUS.REG &&
            <Pointcard
              {...this.props} {...{ condition: { campaign: this.state.campaign, my_customer: this.state.my_customer } }}
            />
          } */}
          {(this.props.page === undefined || this.props.page === null || this.props.page === '') &&
            <Auth
              siteId={this.state.siteId}
              page={this.props.page}
              setting={this.state.setting}
              my_customer={this.state.my_customer}
              setBack={this.setBack}
              setNext={this.setNext}
              liff_access_token={this.props.liff_access_token}
              liff_user_id={this.props.liff_user_id}
              setMyCustomer={this.setMyCustomer}
            />
          }
          {this.props.page === constClass.AUTH &&
            <Auth
              siteId={this.state.siteId}
              page={this.props.page}
              setting={this.state.setting}
              my_customer={this.state.my_customer}
              setBack={this.setBack}
              setNext={this.setNext}
              liff_access_token={this.props.liff_access_token}
              liff_user_id={this.props.liff_user_id}
              setMyCustomer={this.setMyCustomer}
            />
          }
          {this.props.page === constClass.AUTHVERIFY &&
            <AuthVerify
              siteId={this.state.siteId}
              page={this.props.page}
              setting={this.state.setting}
              my_customer={this.state.my_customer}
              setBack={this.setBack}
              setNext={this.setNext}
              liff_access_token={this.props.liff_access_token}
              liff_user_id={this.props.liff_user_id}
              setMyCustomer={this.setMyCustomer}
            />
          }
          {this.props.page === constClass.MENU &&
            <Menu
              siteId={this.state.siteId}
              page={this.props.page}
              setting={this.state.setting}
              my_customer={this.state.my_customer}
              setBack={this.setBack}
              setNext={this.setNext}
              liff_access_token={this.props.liff_access_token}
              liff_user_id={this.props.liff_user_id}
              setMyCustomer={this.setMyCustomer}
              membership={this.state.membership}
            />
          }
          {this.props.page === constClass.USER &&
            <User
              siteId={this.state.siteId}
              page={this.props.page}
              setting={this.state.setting}
              my_customer={this.state.my_customer}
              setBack={this.setBack}
              setNext={this.setNext}
              liff_access_token={this.props.liff_access_token}
              liff_user_id={this.props.liff_user_id}
              setMyCustomer={this.setMyCustomer}
              membership_history={this.state.membership_history}
            />
          }
          {this.props.page === constClass.USERCHANGE &&
            <UserChange
              siteId={this.state.siteId}
              page={this.props.page}
              setting={this.state.setting}
              my_customer={this.state.my_customer}
              setBack={this.setBack}
              setNext={this.setNext}
              liff_access_token={this.props.liff_access_token}
              liff_user_id={this.props.liff_user_id}
              setMyCustomer={this.setMyCustomer}
              membership_history={this.state.membership_history}
            />
          }
          {this.props.page === constClass.RENEWAL &&
            <Renewal
              siteId={this.state.siteId}
              page={this.props.page}
              setting={this.state.setting}
              my_customer={this.state.my_customer}
              setBack={this.setBack}
              setNext={this.setNext}
              liff_access_token={this.props.liff_access_token}
              liff_user_id={this.props.liff_user_id}
              setMyCustomer={this.setMyCustomer}
              membership={this.state.membership}
              setPaymentData={this.setPaymentData}
            />
          }
          {this.props.page === constClass.PAYMENT &&
            <Payment
              {...this.state.payment_data}
              siteId={this.state.siteId}
              lineId={this.props.liff_access_token}
              returnFunc={this.returnPayment}
              backFunc={this.backPayment}
              setting={this.state.setting}
              abortUrl={`/${this.state.siteId}/?page=${constClass.MENU}`}
              setBack={this.setBack}
              setNext={this.setNext}
            />
          }
          {this.props.page === constClass.SPECIFIEDCOMMERCIAL &&
            <SpecifiedCommercial
              {...this.state.payment_data}
              siteId={this.state.siteId}
              lineId={this.props.liff_access_token}
              returnFunc={this.returnPayment}
              backFunc={this.backPayment}
              setting={this.state.setting}
              abortUrl={`/${this.state.siteId}/?page=${constClass.MENU}`}
              setBack={this.setBack}
              setNext={this.setNext}
            />
          }
          {this.props.page === constClass.GUIDE &&
            <Guide
              {...this.state.payment_data}
              siteId={this.state.siteId}
              lineId={this.props.liff_access_token}
              returnFunc={this.returnPayment}
              backFunc={this.backPayment}
              setting={this.state.setting}
              abortUrl={`/${this.state.siteId}/?page=${constClass.MENU}`}
              setBack={this.setBack}
              setNext={this.setNext}
            />
          }
          <Order
            siteId={this.state.siteId}
            page={this.props.page}
            setting={this.state.setting}
            my_customer={this.state.my_customer}
            setBack={this.setBack}
            setNext={this.setNext}
            liff_access_token={this.props.liff_access_token}
            liff_user_id={this.props.liff_user_id}
            setMyCustomer={this.setMyCustomer}
            membership={this.state.membership}
            setPaymentData={this.setPaymentData}
            current_order={this.props.current_order}
            payment_data={this.state.payment_data}
          />
        </div>
        {this.renderBottom()}
        {this.state.disabled && <Loading />}
      </div>
        : this.state.siteId ? this.state.not_liff_flag ? <div className="liff-top bg-lightwhite" style={{ '--header-height': `${this.state.headerHeight}px`, '--footer-height': `${this.state.footerHeight}px` }}>
          <DocumentMeta {...this.meta} />
          {this.renderHeader()}
          <div className="row mx-0">
            <div className="col px-0">
              <div className="row mx-0 my-3 text-center">
                <div className="col px-0">
                  LINEをご利用ください。
                </div>
              </div>
              <div className="row mx-0 px-0-env pt-1 pb-2">
                <div className="col text-center">
                  <button
                    disabled={this.state.disabled}
                    className={`btn-lg btn-enable w-100 py-3 p-env-bottom`}
                    onClick={() => { window.location.replace(this.state.setting['LINE_LIFF_URL']); }}>
                    LINEで開く
                  </button>
                </div>
              </div>
              <div className="row mx-0 px-0-env py-1">
                <div className="col text-left">
                  <span>LINEが開かない場合、以下をお試しください。</span>
                </div>
              </div>
              <div className="row mx-0 px-0-env pt-1">
                <div className="col text-left">
                  <span>・iOSの場合</span>
                </div>
              </div>
              <div className="row mx-0 px-0-env pb-1">
                <div className="col text-left bg-white border my-1 mx-3">
                  <span>以下のリンクを長押し後、上にスワイプし、メニューに表示されている「LINEで開く」をタップしてください。<br />
                  <a href={this.state.setting['LINE_LIFF_URL']}>リンク</a>
                  </span>
                </div>
              </div>
              <div className="row mx-0 px-0-env pt-1">
                <div className="col text-left">
                  <span>・Androidの場合</span>
                </div>
              </div>
              <div className="row mx-0 px-0-env pb-1">
                <div className="col text-left bg-white border my-1 mx-3">
                  <span>設定 → アプリ → LINE → デフォルトで開く → 対応リンクを開く が有効になっていることを確認してください。</span>
                </div>
              </div>
            </div>
          </div>
        </div>
          : <Loading /> : <Loading />)
  }
}

export default withRouter(Liff);